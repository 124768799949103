(function(_, Mustache) {
    'use strict';

    window.App = {};

    var data = document.getElementById('data');
    window.settings = JSON.parse(data.dataset.settings);

    App.settings = _.extend({}, window.settings, {
        showRecoverPassword: false,
        showSingleSignon: false,
        anchorTag: window.location.hash || false // Handles hashes in URL such as #reports/securitycategoryqueries
    });

    $(document).ready(initialize);

    function initialize() {

        if (App.settings.page === 'loading.php') {
            handleLoading();
        } else {
            handlePage();
        }

        addBodyClass(App.settings.page);

        addPageTitle(App.settings.page);
        return;
    }

    function handlePage() {
        addEvents();
        var map = App.map;

        _getUserDetails(function(user) {
            App.settings.user = user;

            var curHourOverride = App.settings.time || false; // Used for overriding the time via GET Query e.g. '?time=10'

            var mappedValues = App.personalizationService(map, user, curHourOverride);

            var data = _.extend({}, user, {
                signupUrl: 'https://signup.opendns.com/freetrial/',
                learnmoreUrl: 'https://labs.opendns.com'
            });

            var localeFile;

            if (App.settings.theme == 'umbrella') {
                localeFile = '/i18n/locale-umbrella-en.json';
            } else {
                localeFile = '/i18n/locale-opendns-en.json';
            }

            $.getJSON(localeFile, function(localeEn) {
                var userGreeting = Mustache.render(_getObject(localeEn, mappedValues.userGreeting), data);
                var opendnsGreeting = Mustache.render(_getObject(localeEn, mappedValues.opendnsGreeting), data);
                var cta = Mustache.render(_getObject(localeEn, mappedValues.cta), data);

                $('.user-greeting').html(userGreeting);
                $('.opendns-greeting').html(opendnsGreeting);
                $('.cta-links').html(cta);

                addEvents();
            });

            setBackground(mappedValues.background);

            if (!_.isUndefined(user)) {
                // If user is known to have used SAML
                if (user.ssoEnabled) {
                    toggleSingleSignOn();
                }
            }

            if (!_.isUndefined(App.settings.sso) &&
                App.settings.sso === true
            ) {
                toggleSingleSignOn();
            }
        });

        // #####################
        // LOGIN-PAGE specific stuff
        // #####################
        if (App.settings.page === 'login.php' &&
            $('form[name="signin"]').length > 0
        ) {
            if ($('#username').val() === '') {
                $('#username').focus();
            } else {
                $('#password').focus();
            }

            $('form[name="signin"]').submit(function() {
                if ($('#username').val() === '') {
                    return false;
                }
            });

            // Store values after hash tag in URL (e.g. #/configuration)
            if (App.settings.anchorTag) {
                simpleStorage.set('anchorTag', App.settings.anchorTag);
            } else {
                simpleStorage.deleteKey('anchorTag');
            }

        } else if (App.settings.page === 'twofactor_auth.php') {
            $('#verification_code').focus(); // Focus on verification code field
        }
    }

    function handleLoading() {
        var map = App.map;

        var return_to = App.settings.return_to;

        // Store user details
        _storeUserDetails();

        // Get existing user details
        _getUserDetails(function(user) {
            App.settings.user = user;
            var mappedValues = App.personalizationService(map, user);
            setBackground(mappedValues.background);
        });

        // Modify return_to to include url after # (e.g. #/configuration)
        if (!_.isUndefined(window.simpleStorage) &&
            !_.isUndefined(simpleStorage.get('anchorTag')) &&
            simpleStorage.get('anchorTag')
        ) {
            return_to += simpleStorage.get('anchorTag');
            simpleStorage.deleteKey('anchorTag');
        }

        window.location = return_to;

    }

    function toggleRecoverPassword() {
        App.settings.showRecoverPassword = !App.settings.showRecoverPassword;

        if (App.settings.showRecoverPassword) {
            $('.form-login').addClass('hidden');
            $('.lockedout-message').addClass('hidden');
            $('.form-recoverpassword').removeClass('hidden');
        } else {
            $('.form-login').removeClass('hidden');
            $('.lockedout-message').removeClass('hidden');
            $('.form-recoverpassword').addClass('hidden');
        }
    }

    function toggleSingleSignOn() {
        App.settings.showSingleSignon = !App.settings.showSingleSignon;

        if (App.settings.showSingleSignon) {
            $('.form-login').addClass('hidden');
            $('.form-ssologin').removeClass('hidden');
        } else {
            $('.form-login').removeClass('hidden');
            $('.form-ssologin').addClass('hidden');
        }
    }

    function switchUser() {
        if (!_.isUndefined(window.simpleStorage)) {
            simpleStorage.deleteKey('user');
        }

        location.reload();
    }

    function addEvents() {
        $('.switch-user').off().on('click', switchUser);
        $('.forgot-password-link').off().on('click', toggleRecoverPassword);
        $('.sso-link').off().on('click', toggleSingleSignOn);
    }

    function setBackground(background) {
        // console.log("background", background);
        // $('body').css('background-image', 'url(' + background.src + ')'); // TODO Enable alternating backgrounds
        $('.background-caption-link')
            // .text(background.caption);
            .text('OpenDNS Graphiti Project');

        if (background.link) {
            $('.background-caption-link')
                .attr('href', background.link)
                .removeClass('no-link');
        }
    }

    function addBodyClass(page) {
        var pageName = page.replace('.php', '');
        $('body').addClass('page_' + pageName);
    }

    function addPageTitle(page) {
        var pageTitle = '';
        switch (page) {
            case 'login.php':
                pageTitle = 'Login';
                break;
            case 'twofactor_auth.php':
                pageTitle = 'Two Factor Authorization';
                break;
            case 'twofactor_recovery.php':
                pageTitle = 'Two Factor Authorization';
                break;
            case 'resetpassword.php':
            case 'reset_password_request.php':
            case 'reset_password_process.php':
                pageTitle = 'Reset Password';
                break;
            case 'new_user_set_password_and_info.php':
                pageTitle = 'New User';
                break;
            default:
                var reformatted = page.replace('.php', '');

                var words = reformatted.split('_');
                for (var i = 0; i < words.length; i++) {
                    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
                }
                pageTitle = words.join(' ');
                break;
        }
        $(document).prop('title', App.settings.brandName + ' > ' + pageTitle);
    }

    function _getUserMessage(user) {
        var greeting = 'Good evening';
        return greeting + ' ' + user.fullName + '!';
    }

    function _getOpendnsMessage(user) {
        return 'Uh oh. It looks late in your timezone, hope it\'s nothing serious.';
    }

    function _storeUserDetails(cb) {
        cb = cb || function() {};

        if (!_.isUndefined(window.simpleStorage)) {
            simpleStorage.set('user', {
                firstName: App.settings.firstName || false,
                lastName: App.settings.lastName || false,
                fullName: App.settings.firstName + ' ' + App.settings.lastName,
                userId: App.settings.userId || false,
                ssoEnabled: App.settings.sso || false
            });

            cb();
        }
    }

    function _getUserDetails(cb) {
        cb = cb || function() {};

        if (!_.isUndefined(window.simpleStorage)) {
            var user = simpleStorage.get('user') || false;

            if (user && (
                    !user.firstName ||
                    _.isEmpty(user.firstName) ||
                    !user.lastName ||
                    _.isEmpty(user.lastName)
                )
            ) {
                user = false;
            }

            cb(user);
        }
    }

    /**
     * Iterate through a string of object keys, returning false it doesn't exist.
     * @param {Object} base - An object that exists
     * @param {String} objString - A string of an object you would like to check (e.g. 'user.name.firstname')
     * @returns {Object} The resulting value, or false
     */
    function _getObject(base, objString) {
        var keys = objString.split('.');
        var curObj = base;

        for (var i = 0; i < keys.length; i++) {
            if (curObj) {
                checkKey(curObj, keys[i], i);
            }
        }

        function checkKey(obj, key, i) {
            if (_.isUndefined(obj[key])) {
                curObj = false;
            } else {
                curObj = obj[key];
            }
        }

        return curObj;
    }

    function _getLocation(url) {
        var l = document.createElement("a");
        l.href = url;
        return l;
    }

})(window._, window.Mustache);

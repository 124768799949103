(function(_) {
    'use strict';

    App.map = [{
            // #############################################
            // KNOWN USER
            // #############################################

            // ###############
            // Known user, Morning (5am to 11:59am)
            // ###############
            condition: function(opts) {
                var curHour = opts.datetime.curHour;
                if (opts.userKnown &&
                    curHour >= 5 && curHour <= 11
                ) {
                    return true;
                }
                return false;
            },
            values: {
                userGreeting: 'GREETINGS.USER_KNOWN.USER_GREETING.MORNING',
                opendnsGreeting: 'GREETINGS.USER_KNOWN.OPENDNS_GREETING.MORNING',
                cta: 'GREETINGS.USER_KNOWN.CTA',
                background: {
                    src: '/img/bg/1.jpg',
                    link: false,
                    caption: 'OpenDNS Vancouver in the morning'
                }
            }
        },
        // ###############
        // Known user, Afternoon (12pm to 5:59pm)
        // ###############
        {
            condition: function(opts) {
                var curHour = opts.datetime.curHour;
                if (opts.userKnown &&
                    curHour >= 12 && curHour <= 17
                ) {

                    return true;
                }
                return false;
            },
            values: {
                userGreeting: 'GREETINGS.USER_KNOWN.USER_GREETING.AFTERNOON',
                opendnsGreeting: 'GREETINGS.USER_KNOWN.OPENDNS_GREETING.AFTERNOON',
                cta: 'GREETINGS.USER_KNOWN.CTA',
                background: {
                    src: '/img/bg/2.jpg',
                    link: false,
                    caption: 'OpenDNS San Francisco office'
                }
            }
        },
        // ###############
        // Known user, Evening (6pm to 12:59am)
        // ###############
        {
            condition: function(opts) {
                var curHour = opts.datetime.curHour;
                if (opts.userKnown &&
                    (curHour >= 6 || curHour <= 0)
                ) {
                    return true;
                }
                return false;
            },
            values: {
                userGreeting: 'GREETINGS.USER_KNOWN.USER_GREETING.EVENING',
                opendnsGreeting: 'GREETINGS.USER_KNOWN.OPENDNS_GREETING.EVENING',
                cta: 'GREETINGS.USER_KNOWN.CTA',
                background: {
                    src: '/img/bg/3.jpg',
                    link: false,
                    caption: 'Umbrella Graphiti Project'
                }
            }
        },
        // ###############
        // Known user, Dusk (1am to 4:59am)
        // ###############
        {
            condition: function(opts) {
                var curHour = opts.datetime.curHour;
                if (opts.userKnown &&
                    (curHour >= 1 || curHour <= 4)
                ) {

                    return true;
                }
                return false;
            },
            values: {
                userGreeting: 'GREETINGS.USER_KNOWN.USER_GREETING.DUSK',
                opendnsGreeting: 'GREETINGS.USER_KNOWN.OPENDNS_GREETING.DUSK',
                cta: 'GREETINGS.USER_KNOWN.CTA',
                background: {
                    src: '/img/bg/4.jpg',
                    link: false,
                    caption: 'Umbrella Graphiti Project'
                }
            }
        },

        // #############################################
        // UNKNOWN USER
        // #############################################

        // ###############
        // Unknown user, Morning (5am to 11:59am)
        // ###############
        {
            condition: function(opts) {
                var curHour = opts.datetime.curHour;
                if (!opts.userKnown &&
                    curHour >= 5 && curHour <= 11
                ) {
                    return true;
                }
                return false;
            },
            values: {
                userGreeting: 'GREETINGS.USER_UNKNOWN.USER_GREETING.MORNING',
                opendnsGreeting: 'GREETINGS.USER_UNKNOWN.OPENDNS_GREETING.MORNING',
                cta: 'GREETINGS.USER_UNKNOWN.CTA',
                background: {
                    src: '/img/bg/1.jpg',
                    link: false,
                    caption: 'OpenDNS Vancouver in the morning'
                }
            }
        },
        // ###############
        // Unknown user, Afternoon (12pm to 5:59pm)
        // ###############
        {
            condition: function(opts) {
                var curHour = opts.datetime.curHour;
                if (!opts.userKnown &&
                    curHour >= 12 && curHour <= 17
                ) {
                    return true;
                }
                return false;
            },
            values: {
                userGreeting: 'GREETINGS.USER_UNKNOWN.USER_GREETING.AFTERNOON',
                opendnsGreeting: 'GREETINGS.USER_UNKNOWN.OPENDNS_GREETING.AFTERNOON',
                cta: 'GREETINGS.USER_UNKNOWN.CTA',
                background: {
                    src: '/img/bg/2.jpg',
                    link: false,
                    caption: 'OpenDNS San Francisco office'
                }
            }
        },
        // ###############
        // Unknown user, Evening (6pm to 12:59am)
        // ###############
        {
            condition: function(opts) {
                var curHour = opts.datetime.curHour;
                if (!opts.userKnown &&
                    (curHour >= 6 || curHour <= 0)
                ) {
                    return true;
                }
                return false;
            },
            values: {
                userGreeting: 'GREETINGS.USER_UNKNOWN.USER_GREETING.EVENING',
                opendnsGreeting: 'GREETINGS.USER_UNKNOWN.OPENDNS_GREETING.EVENING',
                cta: 'GREETINGS.USER_UNKNOWN.CTA',
                background: {
                    src: '/img/bg/3.jpg',
                    link: false,
                    caption: 'OpenDNS Graphiti Project'
                }
            }
        },
        // ###############
        // Unknown user, Dusk (1am to 4:59am)
        // ###############
        {
            condition: function(opts) {
                var curHour = opts.datetime.curHour;
                if (!opts.userKnown &&
                    (curHour >= 1 || curHour <= 4)
                ) {
                    return true;
                }
                return false;
            },
            values: {
                userGreeting: 'GREETINGS.USER_UNKNOWN.USER_GREETING.DUSK',
                opendnsGreeting: 'GREETINGS.USER_UNKNOWN.OPENDNS_GREETING.DUSK',
                cta: 'GREETINGS.USER_UNKNOWN.CTA',
                background: {
                    src: '/img/bg/4.jpg',
                    link: false,
                    caption: 'OpenDNS Graphiti Project'
                }
            }
        }
    ];


})(window._);

(function(_, moment) {
    'use strict';

    App.personalizationService = personalizationService;

    function personalizationService(personalizationMap, currentUser, curHourOverride) {
        var opts = {
            currentUser: currentUser,
            // some users have blank firstName, those we consider unknown
            userKnown: false,
            datetime: {
                curHour: curHourOverride || parseInt(moment().format('HH'))
            }
        };

        if (currentUser &&
            currentUser.firstName !== "false" &&
            !_.isEmpty(currentUser.firstName)
        ) {
            opts.userKnown = true;
        }

        var activePersonalization = _.find(personalizationMap, function(p) {
            if (p.condition(opts)) {
                return true;
            } else {
                return false;
            }
        });

        var getUserGreeting = function() {
            if (_.isFunction(activePersonalization.values.userGreeting)) {
                return activePersonalization.values.userGreeting(opts);
            } else {
                return activePersonalization.values.userGreeting;
            }
        };

        var getOpendnsGreeting = function() {
            if (_.isFunction(activePersonalization.values.opendnsGreeting)) {
                return activePersonalization.values.opendnsGreeting(opts);
            } else {
                return activePersonalization.values.opendnsGreeting;
            }
        };

        var getCta = function() {
            if (_.isFunction(activePersonalization.values.cta)) {
                return activePersonalization.values.cta(opts);
            } else {
                return activePersonalization.values.cta;
            }
        };

        var getBackground = function() {
            return activePersonalization.values.background;
        };

        return {
            userGreeting: getUserGreeting(),
            opendnsGreeting: getOpendnsGreeting(),
            cta: getCta(),
            background: getBackground()
        };
    }
})(window._, window.moment);
